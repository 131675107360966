@import url('https://fonts.googleapis.com/css2?family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gentium+Book+Plus&family=Hurricane&display=swap');

.title-header{
    font-family: "Gentium Book Plus", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
}

.width-max-content {
    width: max-content;
}

.name-company{
    font-family: "Hurricane", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    margin: auto;
    color: #1D3557;
}


.header{
    height: 80px;
    background-image: linear-gradient(to right, #F5CFCF , #FFFFFF);
}

@media (max-width: 767px) {

    #navbarNav{
        ul {
            div{
                background-color: #ffffff;
            }
        }
    }
    .name-company {
        font-size: 30px;
    }

    .ul-menu{
        top: 70px;
    }
}