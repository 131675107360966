* {
    box-sizing: inherit; /* Inherit box-sizing from html/body */
}


html, body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    width: 100%; /* Ensure no element exceeds the viewport width */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}

.carousel-0{
    border-radius: 50px;
    margin-top: 40px;
    margin-left: 40px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

@media (max-width: 767px) {
    .carousel-0{
        border-radius: 10px;
        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
        width: 95%;
    }
}