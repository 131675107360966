@import url('https://fonts.googleapis.com/css2?family=Gentium+Book+Plus&family=Hurricane&display=swap');

.slogan{
    font-family: "Hurricane", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    margin: auto;
    color: #722929;
}

.content-wrapper {
    text-align: center; /* Căn giữa văn bản */
}

.circle {
    width: 500px; /* Đường kính của hình tròn */
    height: 500px;
    background-color: #3498db; /* Màu nền của hình tròn */
    border-radius: 50%; /* Tạo hình tròn */
    position: absolute; /* Cố định vị trí */
    top: -50px; /* Khoảng cách từ mép trên của trang */
    right: -50px; /* Khoảng cách từ mép trái của trang */
    background-image: linear-gradient(to bottom, #F1FAEE , #F4DFDF);
    display: flex; /* Sử dụng Flexbox */
    justify-content: center; /* Căn giữa theo chiều ngang */
    align-items: center; /* Căn giữa theo chiều dọc */
}

@media (max-width: 767px) {
    .circle{
        display: none;
    }
}