.introduce{
    margin-top: 50px;
}

.icon-intro{
    margin: 10px 0 10px 0;

    img{
        width: 100px;
        height: 100px;
    }

    .email{
        width: 150px;
    }
}