.carousel-inner{
    border-radius: 50px;
}

.carousel-item{
    border-radius: 50px;
}

@media (max-width: 767px) {
    .carousel-inner{
        border-radius: 10px;
    }
    
    .carousel-item{
        border-radius: 10px;
    }
}