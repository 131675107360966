@import url('https://fonts.googleapis.com/css2?family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
footer{
    background-color: #1D3557;
    margin-top: 100px;
}

.trirong-light {
    font-family: "Trirong", serif;
    font-weight: 300;
    font-style: normal;
  }

.name-footer-company{
    padding-top: 5px;
    padding-bottom: 0px;
    font-size: 15px;
    color: #ffffff;
    margin-left: 50px;
    margin-bottom: 0px;
}

.infor-company{
    padding-bottom: 2px;
    margin: 0px;
    color: #ffffff;
    margin-left: 50px;
}

.logo-footer{
    width: 150px;
}

@media (max-width: 767px) {
    footer{
        margin-top: 20px;
    }

    .name-footer-company{
        padding-top: 2px;
        padding-bottom: 0px;
        font-size: 10px;
        margin-left: 10px;
        margin-bottom: 0px;
    }
    
    .infor-company{
        padding-bottom: 2px;
        margin-left: 10px;
        font-size: 10px;
    }

    .logo-footer{
        display: none;
    }
}