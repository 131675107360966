@import url('https://fonts.googleapis.com/css2?family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.list-product{
    margin-top: 50px;
    padding: 0px;
}

.trirong-regular {
    font-family: "Trirong", serif;
    font-weight: 400;
    font-style: normal;
}

.title-0{
    margin: 50px 0px 0px 0px;
    font-size: 30px;
}

.item {
    margin-top: 50px;
    max-width: 350px;
    height: 500px;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
    border-radius: 5px;
    overflow: hidden; /* Ngăn tràn */
    position: relative; /* Cần thiết để định vị .hover-item */
    display: flex;
    flex-direction: column; /* Sắp xếp các phần tử con theo chiều dọc */
    
    .image-item {
        max-width: 100%;      /* Make the image-item responsive */
        height: auto;        /* Set a fixed height for the image container */
        border-radius: 5px;
        box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
        display: block; /* Đảm bảo hình ảnh là phần tử block */
        flex-shrink: 0; /* Ngăn hình ảnh bị co lại */

        .image{
            max-width: 100%;      /* Make the image-item responsive */
            height: auto; 
        }
    }
    
    .name-item{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        font-size: 25px;
        justify-content: center;
        align-items: center;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        .hover-item {
            display: none;
            
        }
    }


}

.item:hover{
    box-shadow: rgba(221, 11, 11, 0.4) 0px 0px 0px 2px, rgba(194, 20, 20, 0.65) 0px 4px 6px -1px, rgba(211, 39, 39, 0.08) 0px 1px 0px inset;
    border-radius: 5px;

    .image-item {
        max-width: 100%;      /* Make the image-item responsive */
        height: auto;        /* Set a fixed height for the image container */
        border-radius: 5px;
        box-shadow: rgb(184, 8, 8) 0px 20px 30px -10px;
        opacity: 80%;
    }

    .name-item{
        p{
            display: none;
        }

        .hover-item{
            position: absolute; /* Allows positioning within .item */
            height: 50%;
            width: 100%;
            background-color: #BF1A1A;
            transition: transform 0.3s ease; /* Smooth transition for hover effect */
            border-radius: 0px 0px 5px 5px;
            justify-content: center;
            align-items: center;
            display: flex;
            left: 0;
            bottom: 0;

            p{
                display: block;
                margin: 0px;
                color: #ffffff;
            }
        }
    }
}

@media (max-width: 767px) {
    .list-product{
        margin-top: 20px;
    }
    .title-0{
        font-size: 20px;
        margin: 10px 0px 10px 0px;
    }

    .item{
        margin-top: 15px;
        width: 150px;
        height: 200px;

        .name-item{
            p{
                font-size: 10px;
            }
        }
    }

    .item:hover{
        .name-item{
            .hover-item{
                p{
                    font-size: 10px;
                }
            }
        }
    }
}


