@import url('https://fonts.googleapis.com/css2?family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.adver{
    margin-top: 100px;
}

.trirong-regular {
    font-family: "Trirong", serif;
    font-weight: 400;
    font-style: normal;
}

.trirong-extralight {
    font-family: "Trirong", serif;
    font-weight: 300;
    font-style: normal;
  }

.title-0{
    font-size: 30px;
}

.title-1{
    font-size: 20px;
}

.title-2{
    font-size: 15px;
}


@media (max-width: 767px) {
    .adver{
        margin-top: 50px;
    }

    .image-icon{
        width: 50px;
        height: 50px;
    }
    .title-0{
        font-size: 20px;
        margin-bottom: 0px;
    }
    

    .title-1{
        font-size: 13px;
    }

    .title-2{
        font-size: 8px;
    }
}
