@import url('https://fonts.googleapis.com/css2?family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.trirong-bold {
    font-family: "Trirong", serif;
    font-weight: 700;
    font-style: normal;
}

.name-company-infor{
    font-size: 25px;
    color: #CA1616;
}

.trirong-regular-italic {
    font-family: "Trirong", serif;
    font-weight: 400;
    font-style: italic;
}

.infor{
    background-color: #F3FEFE;
    margin-top: 100px;

}

.centered-img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 767px) {
    .infor{
        margin-top: 20px;
    }

    .name-company-infor{
        font-size: 15px;
        text-align: center;
        color: #CA1616;
    }

    .noti-company{
        font-size: 10px;
        margin: 0px 15px 0px 15px;
    }
}